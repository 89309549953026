import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/common/layout'
import SEO from '../components/common/seo'
import MusicSingle from '../components/music/single'

function MusicPage ({ data }) {
	const singles = data.singles.edges
	const pageDetails = data.musicDeets.edges[0].node

	return (
		<Layout pageTitle={pageDetails.title}>
			<SEO
				title={pageDetails.title}
				description={pageDetails.description}
				img={pageDetails.shareImage.file.url}
			/>

			<h1 className="title">Singles</h1>
			<div className="columns is-centered has-text-centered">
				{singles.map((single) =>
					<MusicSingle single={single.node} key={single.node.title} />
				)}
			</div>
			<hr />
      
			<div className="music-listen has-text-centered">
				<h1 className="is-size-2 is-uppercase music-available">Available on</h1>
				<div className="music-links">
					<a className="music-listen-link link-spotify" href="https://open.spotify.com/artist/2J6EBT68wH17kBj6G7burz?si=lce6ejZFS6uKVey7nLf9hg" target="_blank" rel="noopener noreferrer">
						<span className="icon is-medium is-size-1"><i className="ri-spotify-fill" /></span>
					</a>
					<a className="music-listen-link has-text-black" href="https://music.apple.com/gb/artist/toyosi-soetan/1482249213" target="_blank" rel="noopener noreferrer">
						<span className="icon is-medium is-size-1">	<i className="ri-apple-fill" /></span>
					</a>
					<a className="music-listen-link link-youtube" href="https://www.youtube.com/channel/UCBIJsu6xF2N378luUe8DtpA" target="_blank" rel="noopener noreferrer">
						<span className="icon is-medium is-size-1"><i className="ri-youtube-fill" /></span>
					</a>
					<a className="music-listen-link link-amazon" href="https://music.amazon.co.uk/artists/B07Z5MNTQZ" target="_blank" rel="noopener noreferrer">
						<span className="icon is-medium is-size-1"><i className="ri-amazon-fill" /></span>
					</a>
					<div className="buttons is-centered music-listen-more">
						<button className="button is-dark is-medium">
							<a href="https://www.deezer.com/en/artist/75511252" target="_blank" rel="noopener noreferrer">Deezer</a>
						</button>
						<button className="button is-dark is-medium">
							<a href="https://listen.tidal.com/artist/17005694" target="_blank" rel="noopener noreferrer">Tidal</a>
						</button>
						<button className="button is-dark is-medium">
							<a href="https://gb.napster.com/artist/toyosi-soetan" target="_blank" rel="noopener noreferrer">Napster</a>
						</button>
					</div>
				</div>
			</div>

		</Layout >
	)
}

export default MusicPage

export const query = graphql`
  {
    singles:allContentfulSong(filter: {released: {eq: true}}) {
      edges {
        node {
          title
          releaseDate(formatString: "MMMM Do, YYYY", locale: "en-GB")
          description
		  spotifyEmbedCode
		  appleLink
          napsterLink
          youtubeLink
          amazonMusicLink
          artwork {
            fluid(maxWidth: 250) {
              ...GatsbyContentfulFluid
            }
          }
          previewTrack {
            file {
              url
            }
          }
        }
      }
    }
    musicDeets:allContentfulPageDetails(filter: {title: {eq: "Music"}}) {
			edges {
				node {
					title
					description
					shareImage {
						file {
							url
						}
					}
				}
			}
		}
  }
`

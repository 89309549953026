/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import Img from 'gatsby-image'

function MusicSingle({ single }) {

	// Set up hooks for modal
	const [open, setOpen] = useState(false)

	var modalClass = `modal`

	if (open) {
		modalClass = `modal is-active`
	} else {
		modalClass=`modal`
	}

	// Set up iTunes/Apple Music URL
	if (single.appleLink != null)
		var itunesUrl = getStoreUrl(single.appleLink)

	return (
        <>

            <div className="column is-2">
            	<Img className="music-image" fluid={single.artwork.fluid} onClick={() => setOpen(true)} />
            	<h1 className="subtitle music-title">{single.title}</h1>
            	<h2 className="is-uppercase music-subtitle">{single.releaseDate}</h2>
            </div>
            
            {/* Player Modal */}
            <div className={modalClass}>
            	<div className="modal-background"></div>
            	<div className="modal-card">
            		<header className="modal-card-head">
            			<p className="modal-card-title">{single.title} - Single</p>
            			<button className="delete" aria-label="close" onClick={() => setOpen(false)} ></button>
            		</header>
            		<section className="modal-card-body">
            			<div className="columns is-vcentered">
            				<div className="column has-text-left">
            					<p>{single.description}</p><br />
            					{/* <Img fluid={single.artwork.fluid} alt={single.title} className="music-artwork"/> */}
            				</div>
            				<div className="column has-text-left">
            					<div className="music-preview has-text-centered">
            						<div className="music-preview has-text-centered">
            							<iframe className="spotify-iframe" title={single.title} src={`${`https://open.spotify.com/embed/track/${single.spotifyEmbedCode}}`}`} width="300" height="380" frameBorder="10" allowtransparency="true" allow="encrypted-media"></iframe>
            						</div>
            					</div>
            				</div>
            			</div>
            		</section>
            		<footer className="modal-card-foot">
            			<div className="buttons is-centered music-buttons">
            				{single.appleLink &&
								<a href={`${itunesUrl}?app=itunes`} target="__blank" rel="noreferrer nofollow">
									<button className="button is-primary" title="Buy single on iTunes">
									Buy on iTunes&nbsp;
										<span className="icon align-icon"><i className="ri-apple-fill" /></span>
									</button>
								</a>
            				}
            				{single.spotifyEmbedCode &&
								<a href={`${`https://open.spotify.com/track/${single.spotifyEmbedCode}`}`} target="__blank" rel="noreferrer nofollow">
									<button className="button link-spotify"><i className="ri-spotify-fill" /></button>
								</a>
            				}
            				{single.appleLink &&
								<a href={single.appleLink} target="__blank" rel="noreferrer nofollow">
									<button className="button"><i className="ri-apple-fill" /></button>
								</a>
            				}
            				{single.youtubeLink &&
								<a href={single.youtubeLink} target="__blank" rel="noreferrer nofollow">
									<button className="button link-youtube"><i className="ri-youtube-fill" /></button>
								</a>
            				}
            				{single.amazonMusicLink &&
								<a href={single.amazonMusicLink} target="__blank" rel="noreferrer nofollow">
									<button className="button link-amazon"><i className="ri-amazon-fill" /></button>
								</a>
            				}
            			</div>
            		</footer>
            	</div>
            </div>
        </>
	)
}

export default MusicSingle

function getStoreUrl(url) {
	return url.split(`?`)[0]
}